<template>
  <div style="padding-top: 20px" v-if="userInfo">
    <el-card style="width: 400px" :body-style="{ padding: '10px' }">
      <img :src="userInfo.image" style="display: block;
      width: 100%" alt="xgb-user" />
      <div style="padding: 14px">
        <div class="mt-4">昵称： {{userInfo.display_name}}</div>
        <div class="mt-4">角色： {{userInfo.roles}}</div>
        <div class="mt-4">邮箱： {{userInfo.email}}</div>
        <div class="mt-4">uid： {{userInfo.uid}}</div>
        <div class="mt-4" v-if="!isProd">
          <span>开启免权限&nbsp;&nbsp;</span>
          <el-switch size="mini" v-model="allow_auth_free"></el-switch>
        </div>
        <div class="mt-4">
          <span>显示全量菜单&nbsp;&nbsp;</span>
          <el-switch size="mini" v-model="show_banned_menu"></el-switch>
        </div>
        <br />
        <el-button type="danger" @click="logout" icon="el-icon-switch-button">退出登录</el-button>
      </div>
    </el-card>
    <br />
  </div>
</template>
<script>
import store from '@/store'
import { isProd } from '@/utils'
import { userStore } from '@/store/modules/user'

export default {
  name: 'Index',
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    },
    show_banned_menu: {
      set(val) {
        if (val) {
          localStorage.setItem('XGB_ADMIN_SHOW_BANNED_MENU', val)
        } else {
          localStorage.removeItem('XGB_ADMIN_SHOW_BANNED_MENU')
        }
        store.commit('update_common', {
          show_banned_menu: val
        })
      },
      get() {
        return store.state.show_banned_menu
      }
    },
    allow_auth_free: {
      set(val) {
        if (val) {
          localStorage.setItem('XGB_ADMIN_ALLOW_AUTH_FREE', val)
        } else {
          localStorage.removeItem('XGB_ADMIN_ALLOW_AUTH_FREE')
        }
        store.commit('update_common', {
          allow_auth_free: val
        })
        setTimeout(() => location.reload(), 500)
      },
      get() {
        return store.state.allow_auth_free
      }
    }
  },
  data() {
    return {
      isProd
    }
  },
  methods: {
    logout() {
      userStore.dispatchLogout()
    }
  }
}
</script>
